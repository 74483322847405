//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import imgupload from './imgupload.vue';

export default {

    props: {
        // 添加数据
        userid:{
            type:String,
            default:'',
        },
        //详情
        rowguid:{
            type:String,
            default:'',
        },
        listName:{
            type:String,
            default:'',
        },
        // 是否和 申请结算清单做关联
        isSqjs:{
            type:Boolean,
            default: false,
        },
        // 申请结算清单做关联的id
        rowId:{
            type:String,
            default:'',
        },
    },
    components: {imgupload},
    data: function () {
        return {
            form: {
                idcardFrontFile:'',
                idcardBackFile:''
            },
        }
    },
    computed: {

    },
    watch: {
        rowguid:{
            handler(n,o){
                if(n){
                    this.getDetaile();
                }
            },
            deep:true,
        }

    },

    methods: {
        cancle: function () {
            this.closehandler();
        },
        submit: function () {
            let that = this;
            // if(!that.form.idcardFrontFile  || !that.form.idcardBackFile){
            //     return ShowMsg('您有未上传文件','warning');
            // }
            if(!!this.userid){
                this.form.userId = this.userid;
            }
            let msg = !!this.rowguid ? '修改' :'添加';

            that.$post(`${that.getGetters('settUrl')}/settPayee`,this.form ,function(data){
                if(that.isSqjs){
                    that.snSubmit();
                    return;
                }

                this.closehandler();
                ShowMsg(`${msg}成功`,'success');
            },true)  
        },
        // 申请结算清单做关联
        snSubmit:function(){
            let that = this;
            let data = clone(this.form);
            data.id = this.rowId;
            // delete data.isDefault;

            that.$post(`${that.getGetters('settUrl')}/settShengNei/setPayee`,data ,function(data){
                this.closehandler();
                ShowMsg(`${msg}成功`,'success');
            },true)
        },

        getidcardFrontFile:function(e){
            this.form.idcardFrontFile = e.fileUrl;
        },
        getidcardBackFile:function(e){
            this.form.idcardBackFile = e.fileUrl;
        },

        closehandler(){
            this.$emit('close');
            this.$nextTick(()=>{
                this.$refs.form.$refs['form'].resetFields();

                this.form = {
                    accountName:'',
                    idcardFrontFile:'',
                    idcardBackFile:'',
                    bankMobile:'',
                    idcardNumber:'',
                    isDefault:'',
                }
            });

        },

        getDetaile(){
            if(!this.rowguid){ return}
            this.$get(`${this.getGetters('settUrl')}/settPayee/${this.rowguid}`,function(data){
                this.form = data;
            });
        },


    },
    mounted () {
      
    }
}
