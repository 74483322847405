//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


export default {

    props: {
        userid:{
            type:String,
            default:'',
        },
        loginname:{
            type:String,
            default:'',
        },
        contractFile:{
            type:Object,
            default:function(){
                return {}
            }
        }
    },
    components: {},
    data: function () {
        return {
            form: {
                contractFileName:this.contractFile.contractFileName,
                contractFileUrl:this.contractFile.contractFileUrl,
            },
        }
    },
    computed: {

    },
    watch: {


    },

    methods: {
        cancle: function () {
            this.$emit('close');
        },
        submit: function () {
            let that = this;
            if(!that.form.contractFileUrl){
                return ShowMsg('请上传文件','warning');
            }
            that.$put(`${that.getGetters('userInfoUrl')}/uploadContract/${that.userid}`,that.form,function(data){
                ShowMsg('上传成功','success');
                that.cancle();
            },true)
        },
        getUploadfile:function(val){
            this.form.contractFileName = val.fileName;
            this.form.contractFileUrl = val.fileUrl;
        },
    },
    mounted () {
    }
}
