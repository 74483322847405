//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import editUser from '@/views/user/views/userInfo/editUser'
import settlementSet from '@/views/user/views/userInfo/settlementSet'
import uploadContract from '@/views/user/views/userInfo/uploadContract'
import settlementDatail from '@/views/user/views/userInfo/settlementSetDatail'
import addRegister from '@/views/user/views/userInfo/addRegister'
import setPaymentInfo from '@/views/user/views/userInfo/setPaymentInfo'


export default {
    mixins: [],
    components: {editUser,settlementSet,uploadContract,settlementDatail,addRegister,setPaymentInfo,},
    data: function () {
        return {
            pgData:{
                listType:'1',
            },
            tableData: [],
            tableHeight: this.isAdmin == true ? window.innerHeight - 203 : window.innerHeight - 160,
            userid:'',
            loginname:'',
            dialogTitle: '',
            editUserDialog:false,
            settlementSetDialog:false,
            uploadContractDialog:false,
            settlementSetDatailDialog:false,
            addRegisterDialog:false,
            setPaymentInfoDialog:false,

            lockedstatusList:[
                {value:'正常',key:'0',type:'success'},
                {value:'禁用',key:'1',type:'danger'},
            ],

            auditstatusList:[
                {value:'未审核',key:'0',type:'info'},
                {value:'已审核',key:'1',type:'success'},
            ],

            regTypeStatusList:[
                {value:'自行注册',key:'1',type:'success'},
                {value:'介绍人添加',key:'2',type:'danger'},
            ],

            contractFile:{
                contractFileName:'',
                contractFileUrl:'',
            }
        }
    },
    computed: {
        isAdmin:function(){
            let status = !!this.getQuery('dwcode') ? this.getQuery('dwcode') : this.getGetters('user').dwcode 
            if(status == '1'){
                return true;
            }else{
                return false;
            }
        },

        loginUserId:function(){
            return this.getGetters('user').userid;
        },
        // 渠道 二级 。 企业 一级
        isQD:function(){
            return (this.pgData.listType == '1');
        },
    },
    watch: {
        editUserDialog: function(n, o){
            if(!n){
                this.reload();
            }
        },
        settlementSetDialog: function(n, o){
            if(!n){
                this.reload();
            }
        },
        uploadContractDialog: function(n, o){
            if(!n){
                this.reload();
            }
        },

    },

    methods: {
        doSearch: function () {
            this.$refs.page.queryData();
        },
        reload: function () {
            this.$refs.page.reload();
        },
        //修改
        editUser: function (row) {
            this.userid = row.userid;
            this.dialogTitle = '编辑';
            this.editUserDialog = true;
        },
        //删除
        delUser: function (row) {
            let that = this;
            ShowConfirm('删除后无法撤销，请确认是否删除', 'warning', () => {
                that.$delete(`${that.getGetters('userInfoUrl')}/${row.userid}`,{},function(data){
                    ShowMsg('删除成功','success');
                    that.reload();
                })
            });
        },
        //结算设置
        settlementSet: function (row) {
            this.userid = row.userid;
            this.dialogTitle = '结算设置';
            this.settlementSetDialog = true;
        },
        setPayment: function (row) {
            this.userid = row.userid;
            this.dialogTitle = '设置收款信息';
            this.setPaymentInfoDialog = true;
        },
        //上传协议
        uploadContract: function (row) {
            this.userid = row.userid;
            this.loginname = row.loginname;
            this.dialogTitle = '上传协议';
            this.contractFile.contractFileName = row.contractFileName;
            this.contractFile.contractFileUrl = row.contractFileUrl;
            this.uploadContractDialog = true;
        },
        //下载协议
        downloadContract: function (row) {
            downloader(row.contractFileUrl)
        },
        //重置密码
        resetPwd: function (row) {
            let that = this;
            ShowConfirm('密码将重置为“abc123”', 'warning', function() {
                that.$put(that.$store.getters.userInfoUrl + '/resetPwd/' + row.userid, {}, function(data, res) {
                    ShowMsg('密码重置成功','success');
                    that.doSearch();
                })
            })
        },
        //启用&&禁用
        setUsing:function(row){
            let that = this;
            ShowConfirm(`是否${row.locked == 1 ? '启用' : '禁用'}当前用户：${row.loginname}`, 'warning', function() {
                that.$put(that.$store.getters.userInfoUrl + '/enableOrDisable/' + row.userid, {}, function(data, res) {
                    ShowMsg('设置成功','success');
                    that.doSearch();
                })
            })
        },
        //审核
        audit:function(row){
            let that = this;
            ShowConfirm('确定审核通过?', 'warning', () => {
                that.$put(that.$store.getters.userInfoUrl + '/audit/'+row.userid,{},function(data){
                    ShowMsg('审核通过','success');
                    that.reload();
                })
            });
        },
        
        //查看结算设置
        showSettlementSetDatai:function(row){
            this.userid = row.userid;
            this.dialogTitle = '结算设置详情';
            this.settlementSetDatailDialog = true;
        },

        addRegister:function(){
            this.dialogTitle = '添加注册';
            this.addRegisterDialog = true; 
        },

        addRegisterClose:function(val){
            this.addRegisterDialog = false;
            if(val=== 'submit'){
                this.doSearch();
            }
        },

        filterDiyData:function(arr,val){
            let status =  arr.filter(function(item){
                return item.key == val ;
            })
            if(status.length > 0){
                return status[0]; //返回筛选的对象
            } else{
                return {};
            }
        },
        afterQuery:function(){
            if(this.isAdmin){
                this.tableHeight = window.innerHeight - 203;
            }
        },
    },
    mounted () {
        console.log( this.getGetters('user'));
    }
}
