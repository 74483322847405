//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//




export default {
    components: {},
    props: {
        userid: {
            type: String,
            default: ''
        },
        resUrl: {
            type: String,
            default: '/bhtong/userInfo'
        },
        type: {
            type: String,
            default: ''
        },
        isReadonly:{
            type: Boolean,
            default:false,
        }

    },
    data () {
        return {
            form:{
                dwrateList:[
                    {
                        guaranteetype:'',
                        guaranteemedia:'',
                        agencytype:'',
                        isChangnei:'',
                        receType:this.getGetters('user').registertype,
                        settRate:'',
                        // rewardRate:'',
                        divideRate:'',
                        rateType:'1',
                        areaCode:'',
                        areaName:'',
                    },
                    {
                        guaranteetype:'',
                        guaranteemedia:'',
                        agencytype:'',
                        isChangnei:'',
                        receType:this.getGetters('user').registertype,
                        settRate:'',
                        divideRate:'',
                        rateType:'2',
                        areaCode:'',
                        areaName:'',
                    },
                ]
			},
            rateType:'1',

            bhlx:[],
            bhjz:[],
            chjg:[],

            isChangneiList:[
                {key:'0',value:'否'},
                {key:'1',value:'是'},
            ],

            test:[
                {key:'0',value:'无'},
                {key:'1',value:'平台'},
                {key:'2',value:'合作方'},
                {key:'3',value:'出函机构'},
            ],

        }
    },
    computed: {

    },  
    watch:{
        'form.dwrateList':{
            handler:function(n,o){
                for(let i= 0 ;i< n.length;i++){
                    if(n[i].settRate > 100){
                        this.form.dwrateList[i].settRate = 100;
                        ShowMsg('结算比例最大值为100','warning');
                    }
                    if(n[i].divideRate > 100){
                        this.form.dwrateList[i].divideRate = 100;
                        ShowMsg('分成比例最大值为100','warning');
                    }
                    // if(n[i].rewardRate > 100){
                    //     this.form.dwrateList[i].rewardRate = 100;
                    //     ShowMsg('奖励分成最大值为100','warning');
                    // }
                }
            },
           deep: true
        },
    },
	methods:{
        //获取结算设置
        getDefaultSet:function(){
            this.$get(`${this.resUrl}/getRate/${this.userid}`, {}, function(data) {
                this.form = data;
                this.rateType = this.form.dwrateList.length > 0 ? this.form.dwrateList[this.form.dwrateList.length - 1].rateType : '1'
                let bhtjsList = this.filterStatus(this.form.dwrateList,1);
                let hcgjsList = this.filterStatus(this.form.dwrateList,2);
                if(bhtjsList.length == 0  &&  hcgjsList.length == 0){
                    this.add(1);
                    this.add(2);
                }else if (bhtjsList.length == 0){
                    this.add(1);
                }else if (hcgjsList.length == 0){
                    this.add(2);
                } 
            })
        },
        //提交
		submit: function(){
            var that = this;
            let bhtjsList = that.filterStatus(that.form.dwrateList,1);
            let hcgjsList = that.filterStatus(that.form.dwrateList,2);
            // && !bhtjsList[0].rewardRate
            if(bhtjsList.length == 1 && !bhtjsList[0].settRate && !bhtjsList[0].divideRate ){
                that.form.dwrateList = hcgjsList;
                //  && !bhtjsList[0].rewardRate
            } else if (hcgjsList.length == 1 && !hcgjsList[0].settRate && !hcgjsList[0].divideRate){
                that.form.dwrateList = bhtjsList;
            }
            that.$put(`${that.resUrl}/setRate/${that.userid}`,that.form,function(data){
                ShowMsg('提交成功','success');
                that.cancle();
            },true)
		},

		cancle: function(){
			this.$emit('close');
		},
        //添加
        add:function(val){
            var obj = {
                guaranteetype:'',
                guaranteemedia:'',
                agencytype:'',
                isChangnei:'',
                receType:this.getGetters('user').registertype,
                settRate:'',
                // rewardRate:'',
                divideRate:'',
                rateType:val,
                areaCode:'',
                areaName:'',
            }
            this.form.dwrateList.push(obj);
        },
        //删除
        del:function(item){
            for(let i=0; i<this.form.dwrateList.length; i++){
                if(JSON.stringify(item) === JSON.stringify(this.form.dwrateList[i])){
                    return this.form.dwrateList.splice(i,1);
                }
            }
        },
        //获取字典数据
        getZD:function(){
            new Chain().link(function (that, next) {
                that.$get(`${that.getGetters('comUrl')}/dict/bhlx`, data => {
                    that.bhlx =data;
                    next();
                    });
                }).link(function (that, next) {
                    that.$get(`${that.getGetters('comUrl')}/dict/bhjz`, data => {
                        that.bhjz =data;
                        next();
                    });
                }).link(function (that, next) {
                    that.$get(`${that.getGetters('comUrl')}/dict/chjg`, data => {
                        that.chjg =data;
                        next();
                    });
                }).run(this);
        },

         //过滤数组
        filterStatus:function(arr,val){
            let newarr =  arr.filter(function(item){
                return item.rateType == val ;
            })
            return newarr
        },
        
	},
	mounted:function(){
        this.getZD();
        this.getDefaultSet();
        
	}
}
