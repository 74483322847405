//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import addPayee from '@/views/settmg/views/ShengNeiSetItem/components/addPayee';

export default {

    props: {
        userid: {
            type: String,
            default: '',
        },
        loginname: {
            type: String,
            default: '',
        },
        contractFile: {
            type: Object,
            default: function () {
                return {};
            }
        }
    },
    components: { addPayee },
    data: function () {
        return {
            form: {

            },
            tableData: [],
            rowguid:'',
        };
    },
    computed: {

    },
    watch: {


    },

    methods: {
        addPayeeclose: function () {
            this.rowguid = '';
            this.getPaymentList();
        },
        cancle: function () {
            this.$emit('close');
        },
        submit: function () {
            let that = this;
            // if (!that.form.contractFileUrl) {
            //     return ShowMsg('请上传文件', 'warning');
            // }
            // that.$put(`${that.getGetters('userInfoUrl')}/uploadContract/${that.userid}`, that.form, function (data) {
            //     ShowMsg('上传成功', 'success');
            //     that.cancle();
            // }, true);
        },

        getPaymentList () {
            let that = this;
            let data = {
                userId: this.userid,
            };
            //     ShowMsg('上传成功', 'success');
            //     that.cancle();

            //     return ShowMsg('您有未上传文件','warning');
            that.$get(`${that.getGetters('settUrl')}/settPayee`, data,function (data) {
                this.tableData = data;
            });
        },
        editUser(val){
            this.rowguid = val.rowguid;
        },
        deleteUser(val){
            let that = this;
            that.$delete(`${that.getGetters('settUrl')}/settPayee/${val.rowguid}`, function (data) {
                ShowMsg('删除成功', 'success');
                this.getPaymentList();
            });
        },

    },
    mounted () {
        this.getPaymentList();
    }
}
