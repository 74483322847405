//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//




export default {
    components: {},
    props: {
        userid: {
            type: String,
            default: ''
        },
        resUrl: {
            type: String,
            default: '/bhtong/userInfo'
        },
    },
    data () {
        return {
            form:{},
            rateType:'',

            bhlx:[],
            bhjz:[],
            chjg:[],

            isChangneiList:[
                {key:'0',value:'否'},
                {key:'1',value:'是'},
            ],

            test:[
                {key:'0',value:'无'},
                {key:'1',value:'平台'},
                {key:'2',value:'合作方'},
                {key:'3',value:'出函机构'},
            ],

        }
    },
    computed: {

    },  
    watch:{

    },
	methods:{
        //获取结算设置
        getSetDetail:function(){
            this.$get(`${this.resUrl}/getRate/${this.userid}`, {}, function(data) {
                this.form = data;
                this.rateType = this.form.dwrateList[this.form.dwrateList.length - 1].rateType
            })
        },
		cancle: function(){
			this.$emit('close');
		},

        //获取字典数据
        getZD:function(){
            new Chain().link(function (that, next) {
                that.$get(`${that.getGetters('comUrl')}/dict/bhlx`, data => {
                    that.bhlx =data;
                    next();
                    });
                }).link(function (that, next) {
                    that.$get(`${that.getGetters('comUrl')}/dict/bhjz`, data => {
                        that.bhjz =data;
                        next();
                    });
                }).link(function (that, next) {
                    that.$get(`${that.getGetters('comUrl')}/dict/chjg`, data => {
                        that.chjg =data;
                        next();
                    });
                }).run(this);
        },

        //过滤数组
        filterStatus:function(arr,val){
            let newarr =  arr.filter(function(item){
                return item.rateType == val ;
            })
            return newarr
        },
        
	},
	mounted:function(){
        this.getZD();
        this.getSetDetail();
	}
}
