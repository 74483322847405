//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    components: {},
    props: {
        action: {
            type: String,
            default: "/bhtong/common/file/uploadFile",
        },
        uploadedImg:{
            type: String,
            default: "",
        },
    },
    data () {
        return {
            imageUrl: ''
        };
    },
    computed: {},
    watch: {
        uploadedImg:{
            handler(n,o){
                // if(n){
                    this.imageUrl = n;
                // }
            },
            deep:true,
            immediate:true,
        }
    },
    methods: {

        beforeUpload: function (file) {
            const isJPG = file.type === 'image/jpeg';
            const isLt10M = file.size / 1024 / 1024 ;
            if (!isJPG) {
                this.$message.error('上传文件格式需为 jpg、jpeg 格式');
                return false;
            }
            if (isLt10M > 10) {
                this.$message.error('上传文件大小不能超过 10MB');
              return false;
            }
            
        },
        //上传成功时
        uploadSuccess: function (response, file, fileList) {
            this.imageUrl = response.tdata.fileUrl;
            this.$emit('setfile', response.tdata);
        },

    },
    mounted: function () { },
};
