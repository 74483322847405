//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//



export default {

    props: {
        userid: {
            type: String,
            default: '',
        },
        // 渠道 和介绍人
        isQD: {
            type: Boolean,
            default: true,
        },
    },
    components: {},
    data: function () {
        return {
            form: {},
            sshyList: [
                { diccode: 'agent', dicname: '招标代理' },
                { diccode: 'college', dicname: '高校' },
                { diccode: 'energy', dicname: '能源' },
                { diccode: 'municipal', dicname: '市政' },
                { diccode: 'consult', dicname: '咨询公司' },
                { diccode: 'hospital', dicname: '医院' },
                { diccode: 'finance', dicname: '金融' },
                { diccode: 'other', dicname: '其他' },
            ],
        };
    },
    computed: {

    },
    watch: {


    },

    methods: {
        cancle: function () {
            this.$emit('close');
        },
        submit: function () {
            let that = this;
            that.$put(`${that.getGetters('userInfoUrl')}/${that.userid}`, that.form, function (data) {
                ShowMsg('修改成功', 'success');
                that.cancle();
            }, true);
        },
        getDetail: function () {
            let that = this;
            that.$get(`${that.getGetters('userInfoUrl')}/${that.userid}`, {}, function (data) {
                this.form = data;
            });
        }

    },
    mounted () {
        this.getDetail();
    }
}
