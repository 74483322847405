//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


export default {

    props: {


    },
    components: {},
    data: function () {
        return {
            loading: false,
            form: {
                khsshy: 'other'
            },
            sshyList: [
                { diccode: 'agent', dicname: '招标代理' },
                { diccode: 'college', dicname: '高校' },
                { diccode: 'energy', dicname: '能源' },
                { diccode: 'municipal', dicname: '市政' },
                { diccode: 'consult', dicname: '咨询公司' },
                { diccode: 'hospital', dicname: '医院' },
                { diccode: 'finance', dicname: '金融' },
                { diccode: 'other', dicname: '其他' },
            ],
        };
    },
    computed: {

    },
    watch: {


    },

    methods: {
        cancle: function (val) {
            this.$emit('close', val);
        },
        submit: function () {
            let that = this;
            this.loading = true;
            this.$ajax({
                url: `${that.getGetters('userInfoUrl')}/add`,
                type: "post",
                data: this.form,

                callback: function (data, res) {
                    ShowMsg('提交成功', 'success');
                    that.cancle('submit');
                },

                complete: function () {
                    that.loading = false;
                }

            });
        },

    },
    mounted () {
    }
}
